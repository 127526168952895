import { Link } from 'gatsby';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { MotherWebsiteText, SectionText } from 'styles/Typography.styled';

export const Container = styled.div`
    align-content: start;
    display: grid;
    grid-gap: 32px;
    padding: 40px 135px 40px 100px;
    position: relative;
    width: 100%;

    ${mediaQueries.lg} {
        padding: 40px;
    }

    ${mediaQueries.md} {
        padding: 80px 16px;
    }

    ${mediaQueries.sm} {
        padding: 0px;
    }
`;

export const Button = styled(Link)(
    ({ theme: { fontSizes, letterSpacing, colors, fontWeight } }) =>
        css`
            align-items: center;
            background: transparent;
            border: none;
            color: ${colors.primaryBlue500};
            cursor: pointer;
            display: flex;
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.bold};
            gap: 8px;
            letter-spacing: ${letterSpacing.s};
            line-height: 28px;

            ${mediaQueries.sm} {
                display: none;
            }
        `,
);

export const SwitchContainer = styled.div(
    ({ theme: { colors, fontSizes, fontWeight } }) => css`
        align-items: center;
        color: ${colors.paleSky};
        display: flex;
        font-size: ${fontSizes.f14}px;
        font-weight: ${fontWeight.bold};
        gap: 16px;
    `,
);

export const Header = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;

    ${mediaQueries.sm} {
        padding: 0 40px;
    }
    ${mediaQueries.xs} {
        padding: 0;
    }
`;

export const Image2D = styled.img`
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
`;

export const Image3D = styled.img`
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
`;

export const InfoBox = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue60};
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 536px;
        padding: 32px 40px;
    `,
);

export const InfoBoxTitle = styled(MotherWebsiteText)(
    ({ theme: { fontWeight } }) => css`
        line-height: 24px;
        font-weight: ${fontWeight.semiBold};
    `,
);

export const InfoBoxText = styled(SectionText)(
    ({ theme: { colors } }) => css`
        color: ${colors.neutral900};
    `,
);

export const InstallmentWrapper = styled.div`
    display: flex;
`;

export const InstallmentPrice = styled.span(
    ({ theme: { fontWeight } }) => css`
        font-weight: ${fontWeight.bold};
    `,
);

export const Divider = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue200};
        height: 2px;
        width: 100%;
    `,
);

export const DetailsText = styled.p(
    ({ theme: { colors, fontSizes, fontWeight } }) => css`
        color: ${colors.neutral300};
        font-size: ${fontSizes.f12};
        font-weight: ${fontWeight.medium};
        line-height: 20px;
    `,
);

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
