import { Switch } from 'components/Switch/Switch';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { ArrowLeft } from 'shared/svg/arrows';
import { FlatType } from 'types/CommonTypes';
import {
    Button,
    Container,
    Header,
    Image2D,
    Image3D,
    InfoBox,
    InfoBoxText,
    InfoBoxTitle,
    SwitchContainer,
    InstallmentPrice,
    Divider,
    InstallmentWrapper,
    DetailsText,
    ImageContainer,
} from './ApartmentProjection.styled';

export interface ApartmentProjectionProps {
    data: FlatType;
}

export const ApartmentProjection = ({ data }: ApartmentProjectionProps) => {
    const [isToggle, setIsToggle] = useState(false);

    const images2d = [
        {
            src: data?.flat?.investmentFlat?.flatImage2d?.sourceUrl,
            alt: data?.flat?.investmentFlat?.flatImage2d?.altText,
        },
        ...(data?.flat?.investmentFlat?.flatImage2dAdditional
            ? [
                  {
                      src: data?.flat?.investmentFlat?.flatImage2dAdditional
                          ?.sourceUrl,
                      alt: data?.flat?.investmentFlat?.flatImage2dAdditional
                          ?.altText,
                  },
              ]
            : []),
    ];

    const images3d = [
        {
            src: data?.flat?.investmentFlat?.flatImage3d?.sourceUrl,
            alt: data?.flat?.investmentFlat?.flatImage3d?.altText,
        },
        ...(data?.flat?.investmentFlat?.flatImage3dAdditional
            ? [
                  {
                      src: data?.flat?.investmentFlat?.flatImage3dAdditional
                          ?.sourceUrl,
                      alt: data?.flat?.investmentFlat?.flatImage3dAdditional
                          ?.altText,
                  },
              ]
            : []),
    ];

    return (
        <Container>
            <Header>
                <Button as="button" onClick={() => navigate(-1)}>
                    <ArrowLeft />
                    Wróć do wyszukiwania
                </Button>
                <SwitchContainer>
                    <Switch
                        id={data.flat.id}
                        value={isToggle}
                        onChange={() => setIsToggle(!isToggle)}
                    />
                    3D widok
                </SwitchContainer>
            </Header>

            {!!data?.flat?.investmentFlat?.enableSafeCreditInfo && (
                <InfoBox>
                    <InfoBoxTitle>
                        {data?.creditBoxData?.safeCreditTitle}
                    </InfoBoxTitle>
                    <InstallmentWrapper>
                        <InfoBoxText>
                            {data?.creditBoxData?.safeCreditInstallmentAmount}{' '}
                            <InstallmentPrice>
                                {
                                    data?.flat?.investmentFlat
                                        ?.safeCreditInstallmentAmount
                                }{' '}
                                zł
                            </InstallmentPrice>
                        </InfoBoxText>
                    </InstallmentWrapper>

                    <InfoBoxText>
                        {data?.creditBoxData?.safeCreditAdditionalInformations}{' '}
                        {data?.flat?.investmentFlat?.safeCreditContactPhone}
                    </InfoBoxText>

                    <Divider />

                    <DetailsText>
                        {data?.creditBoxData?.safeCreditDisclaimer}
                    </DetailsText>
                </InfoBox>
            )}

            {isToggle ? (
                <ImageContainer>
                    {images3d.map((image) => (
                        <Image3D {...image} />
                    ))}
                </ImageContainer>
            ) : (
                <ImageContainer>
                    {images2d.map((image) => (
                        <Image2D {...image} />
                    ))}
                </ImageContainer>
            )}
        </Container>
    );
};
