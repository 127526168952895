import { ApartmentProjection } from 'components/ApartmentProjection/ApartmentProjection';
import { HeartAddToFav } from 'components/HeartAddToFav/HeartAddToFav';
import { ModalForm } from 'components/ModalForm/ModalForm';
import React, { useState } from 'react';
import { H2, Text } from 'styles/Typography.styled';
import { FlatType } from 'types/CommonTypes';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import {
    Building,
    ButtonsWrapper,
    ColorText,
    Container,
    FeaturedBox,
    FlatHeader,
    HeartWrapper,
    IconWrapper,
    InfoTitle,
    InfoValue,
    InfoValueWrapper,
    InfoWrapper,
    Price,
    StyledPrimaryButton,
    StyledSecondaryButton,
    Wrapper,
} from './FlatInfo.styled';
import { FeaturedBoxIcon } from 'shared/svg/featuredBox';

interface FlatInfoProps {
    data: FlatType;
}

export const FlatInfo = ({ data }: FlatInfoProps) => {
    const price = data?.flat?.investmentFlat?.flatPrice?.toLocaleString('pl');
    const priceMeter = Math?.round(
        data?.flat?.investmentFlat?.flatPrice /
            data?.flat?.investmentFlat?.flatArea,
    )?.toLocaleString('pl');
    const isMobile = useBreakpoint('sm');
    const flat = data?.flat?.investmentFlat;
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <>
            <Container>
                <FlatHeader>
                    <H2>Mieszkanie {flat?.flatNumber}</H2>
                    <HeartWrapper>
                        <HeartAddToFav
                            building={flat?.investmentBuilding?.name}
                            id={String(flat?.flatNumber)}
                            size={30}
                        />
                    </HeartWrapper>
                </FlatHeader>
                <InfoValueWrapper margin={!flat?.featuredDescription}>
                    <Building>
                        Budynek {flat?.investmentBuilding?.name}
                    </Building>
                    <svg width="4" height="4" viewBox="0 0 4 4" fill="none">
                        <path
                            d="M2.148 3.164C2.964 3.164 3.648 2.516 3.648 1.64C3.648 0.764 2.964 0.128 2.148 0.128C1.32 0.128 0.648 0.764 0.648 1.64C0.648 2.516 1.32 3.164 2.148 3.164Z"
                            fill="#20263B"
                        />
                    </svg>
                    <ColorText isFree={flat?.flatStatus?.name}>
                        {flat?.flatStatus?.name}
                    </ColorText>
                </InfoValueWrapper>
                {!!flat?.featuredDescription && (
                    <FeaturedBox>
                        <IconWrapper>
                            <FeaturedBoxIcon />
                        </IconWrapper>
                        <Text>{flat?.featuredDescription}</Text>
                    </FeaturedBox>
                )}
                {isMobile && <ApartmentProjection data={data} />}
                <Wrapper>
                    <InfoWrapper>
                        <InfoTitle>Piętro</InfoTitle>
                        <InfoValue>{flat?.buildingFloor?.name}</InfoValue>
                    </InfoWrapper>
                    <InfoWrapper>
                        <InfoTitle>Ilość pokoi</InfoTitle>
                        <InfoValue>{flat?.flatRoomsQuantity}</InfoValue>
                    </InfoWrapper>
                    <InfoWrapper>
                        <InfoTitle>Metraż</InfoTitle>
                        <InfoValue>{flat?.flatArea} m2</InfoValue>
                    </InfoWrapper>
                    <InfoWrapper>
                        <InfoTitle>Zalety</InfoTitle>
                        <InfoValueWrapper>
                            <InfoValue>
                                {flat?.flatAmenities?.name}
                                {flat?.flatFeatured && ', Wykończone'}
                            </InfoValue>
                        </InfoValueWrapper>
                    </InfoWrapper>
                    <InfoWrapper>
                        <InfoTitle>
                            Cena za metr<sup>2</sup>
                        </InfoTitle>
                        <InfoValueWrapper>
                            <InfoValue>{priceMeter} zł</InfoValue>
                        </InfoValueWrapper>
                    </InfoWrapper>

                    <InfoWrapper>
                        <InfoTitle>Cena</InfoTitle>
                        <Price>{price} zł</Price>
                    </InfoWrapper>
                </Wrapper>
                <ButtonsWrapper>
                    {!isMobile && (
                        <StyledPrimaryButton
                            as="button"
                            onClick={() => setModalIsOpen(true)}
                        >
                            Zapytaj o mieszkanie
                        </StyledPrimaryButton>
                    )}
                    {(flat?.pdfBrochure?.sourceUrl ||
                        flat?.pdfBrochure?.mediaItemUrl) && (
                        <StyledSecondaryButton
                            target={'_blank'}
                            to={
                                flat?.pdfBrochure?.sourceUrl ||
                                flat?.pdfBrochure?.mediaItemUrl
                            }
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                                    fill="white"
                                />
                                <path
                                    d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                                    stroke="#466BA2"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                                    fill="white"
                                    stroke="#466BA2"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>{' '}
                            Zobacz kartę mieszkania
                        </StyledSecondaryButton>
                    )}
                </ButtonsWrapper>
                {modalIsOpen && <ModalForm setModalIsOpen={setModalIsOpen} />}
            </Container>
        </>
    );
};
