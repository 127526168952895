import React, { ChangeEvent } from 'react';
import { SwitchWrapper, SwitchInput, SwitchLabel } from './Switch.styled';

interface SwitchProps {
    id: string;
    value: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Switch = ({ id, value, onChange }: SwitchProps) => {
    return (
        <SwitchWrapper>
            <SwitchInput
                id={id}
                type="checkbox"
                checked={value}
                onChange={onChange}
            />
            <SwitchLabel htmlFor={id} />
        </SwitchWrapper>
    );
};
