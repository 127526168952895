import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const MobileFormWrapper = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue50};
        padding: 56px 16px;
    `,
);

export const FormTitle = styled.h2(
    ({ theme: { colors, fontSizes, fontWeight } }) => css`
        color: ${colors.neutral900};
        font-size: ${fontSizes.f30};
        font-weight: ${fontWeight.semiBold};
        line-height: 37px;
        margin-bottom: 32px;
    `,
);
