import React from 'react';
import { Form } from 'sections/Form/Form';
import { MobileFormWrapper, FormTitle } from './MobileForm.styled';

export const MobileForm = () => {
    return (
        <MobileFormWrapper>
            <FormTitle>Skontaktuj się z nami</FormTitle>
            <Form inputIsWhite />
        </MobileFormWrapper>
    );
};
