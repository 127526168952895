import { mediaQueries } from 'shared/breakpoints';
import styled, { css, keyframes } from 'styled-components';

export const ModalFadeIn = keyframes`
    from{
        opacity: 0
    }
    to{
        opacity: 1
    }
`;

export const ModalWrapper = styled.div`
    animation: ${ModalFadeIn} 1s forwards;
    height: 100vh;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    will-change: opacity;
    z-index: 999;
    background-color: rgba(32, 38, 59, 0.5);
`;

export const Content = styled.div(
    ({ theme: { colors } }) => css`
        align-items: center;
        background-color: ${colors.white};
        display: flex;
        margin: 32px auto;
        max-width: 826px;
        min-height: calc(100vh - 64px);
        padding: 64px 128px;
        position: relative;

        ${mediaQueries.sm} {
            padding: 30px 44px;
        }
    `,
);

export const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 38px;
    top: 38px;
`;
