import { ApartmentProjection } from 'components/ApartmentProjection/ApartmentProjection';
import { FlatInfo } from 'components/FlatInfo/FlatInfo';
import React from 'react';
import { FlatType } from 'types/CommonTypes';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import {
    FlatInfoWrapper,
    GalleryWrapper,
    Wrapper,
} from './ApartmentDetails.styled';
import { GallerySlider } from 'components/GallerySlider/GallerySlider';

import { H2 } from 'styles/Typography.styled';
import { MobileForm } from 'components/MobileForm/MobileForm';

interface ApartmentDetailsProps {
    data: FlatType;
}

export const ApartmentDetails = ({ data }: ApartmentDetailsProps) => {
    const isMobile = useBreakpoint('sm');

    const galleryData = data?.flat?.investmentFlat?.flatGallery?.map(
        ({ photo }) => photo?.sourceUrl,
    );

    return (
        <Wrapper>
            <FlatInfoWrapper>
                <FlatInfo data={data} />
                {!isMobile && <ApartmentProjection data={data} />}
            </FlatInfoWrapper>
            {!!galleryData?.length && (
                <GalleryWrapper>
                    <H2>Galeria</H2>
                    <GallerySlider images={galleryData} removePadding />
                </GalleryWrapper>
            )}
            {isMobile && <MobileForm />}
        </Wrapper>
    );
};
