import Layout from 'layout/index';
import * as React from 'react';
import { ApartmentDetails } from 'sections/ApartmentDetails/ApartmentDetails';
import { getDisabledPages } from 'utils/utils';

interface MieszkaniaPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ location, pageContext }: MieszkaniaPageProps) => {
    const pageTitle = `${pageContext?.title} - Mieszaknia - Mieszkanie ${pageContext?.flat?.investmentFlat?.flatNumber}`;

    return (
        <Layout
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            pageTitle={pageTitle}
            location={location}
            investmentDisabledPages={getDisabledPages(pageContext?.headerData)}
        >
            <ApartmentDetails data={pageContext} />
        </Layout>
    );
};
