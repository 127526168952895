import React, { useEffect, useLayoutEffect } from 'react';
import { Form } from 'sections/Form/Form';
import { CloseIcon } from 'shared/svg/closeIcon';
import { CloseButton, Content, ModalWrapper } from './ModalForm.styled';

export interface IProps {
    className?: string;
    setModalIsOpen: (value: boolean) => void;
}

export const ModalForm: React.FC<IProps> = ({ setModalIsOpen, className }) => {
    return (
        <ModalWrapper className={className}>
            <Content>
                <CloseButton onClick={() => setModalIsOpen(false)}>
                    <CloseIcon color="#20263B" />
                </CloseButton>
                <Form isModal />
            </Content>
        </ModalWrapper>
    );
};
