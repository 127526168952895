import styled, { css } from 'styled-components';

export const SwitchWrapper = styled.div`
    display: grid;
    position: relative;
`;

export const SwitchLabel = styled.label(
    ({ theme: { colors } }) => css`
        position: absolute;
        top: 0;
        left: 2px;
        width: 40px;
        height: 24px;
        border-radius: 20px;
        background: ${colors.neutral400};
        cursor: pointer;
        &::after {
            content: '';
            display: block;
            border-radius: 20px;
            width: 20px;
            height: 20px;
            margin: 2px;
            left: 2px;
            top: 50%;
            background: ${colors.white};
            transition: all 0.25s;
        }
        &:hover {
            background-color: ${colors.primaryBlue300};
        }
    `,
);

export const SwitchInput = styled.input(
    ({ theme: { colors } }) => css`
        opacity: 0;
        z-index: 1;
        border-radius: 20px;
        width: 40px;
        height: 24px;
        cursor: pointer;
        &:checked + ${SwitchLabel} {
            background-color: ${colors.primaryBlue500};
            &::after {
                content: '';
                display: block;
                border-radius: 20px;
                width: 20px;
                height: 20px;
                left: 21px;
                margin-left: 18px;
            }
            &:hover {
                background-color: ${colors.primaryBlue700};
            }
        }
    `,
);
