import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';

export const FlatInfoWrapper = styled.div`
    display: grid;
    grid-template-columns: 48% 1fr;

    ${mediaQueries.sm} {
        grid-template-columns: 1fr;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const GalleryWrapper = styled.div`
    padding: 80px 135px;
    display: flex;
    flex-direction: column;
    gap: 48px;

    ${mediaQueries.md} {
        padding: 32px 16px 56px 16px;
    }
`;
